(function () {
    /*This adds the trim method to versions of IE which do not have it.*/
    if(typeof String.prototype.trim !== 'function') {
        String.prototype.trim = function() {
            return this.replace(/^\s+|\s+$/g, '');
        };
    }

    /*detect browser version up to MSIE 10*/
    navigator.browserVersion = (function(){
        var ua= navigator.userAgent,
            N= navigator.appName, tem,
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*([\d\.]+)/i) || [];
        M= M[2]? [M[1], M[2]]:[N, navigator.appVersion, '-?'];
        if(M && (tem= ua.match(/version\/([\.\d]+)/i))!= null) M[2]= tem[1];
        return M.join(' ');
    })();

})();

function validateEstablishmentReview(form) {
    if (!form.starRating.value || form.starRating.value <= 0 || form.starRating.value.length === 0) {
        alert("Please rate this establishment");
        return (false);
    }

    if (!form.title.value || form.title.value.length === 0) {
        alert("Please enter a headline for this review");
        form.title.focus();
        return (false);
    }

    if ((form.anonymous[1].checked) && (form.name.value.length === 0 || !form.name.value)) {
        alert("Please enter your name, or select anonymous");
        form.name.focus();
        return (false);
    }

    if (!form.comments.value || form.comments.value.length === 0) {
        alert("Please enter a detailed comment about the establishment");
        form.comments.focus();
        return (false);
    }

    if (!isCommentDetailedEnough(form)) {
        return (false);
    }

    return (true);
}

function validateLocationReview(form) {
    if (!form.starRating.value || form.starRating.value <= 0 || form.starRating.value.length === 0) {
        alert("Please rate this destination");
        return (false);
    }

    if (!form.title.value || form.title.value.length === 0) {
        alert("Please enter a headline for this review");
        form.title.focus();
        return (false);
    }

    if (!form.comments.value || form.comments.value.length === 0) {
        alert("Please enter a detailed comment about the destination");
        form.comments.focus();
        return (false);
    }

    if (!isCommentDetailedEnough(form)) {
        return (false);
    }

    return (true);
}

function validateRFAReview(form) {
    if (!form.starRating.value || form.starRating.value <= 0 || form.starRating.value.length === 0) {
        alert("Please rate us");
        return (false);
    }

    if (!form.comments.value || form.comments.value.length === 0) {
        alert("Please enter a detailed comment about our product and service");
        form.comments.focus();
        return (false);
    }

    if (!isCommentDetailedEnough(form)) {
        return (false);
    }

    return (true);
}

function isCommentDetailedEnough(form) {
    var comments = form.comments.value;
    comments = comments.replace(/(^\s*)|(\s*$)/gi, "");
    comments = comments.replace(/[ ]{2,}/gi, " ");
    comments = comments.replace(/\n /, "\n");
    if (comments.split(' ').length < 10) {
        alert("Please enter a more detailed comment of at least 10 words");
        form.comments.focus();
        return (false);
    }
    return (true);
}

function validateEmailEnquiryForm(form) {
    var result = validateArriveDepartDateFormjQuery();
    if (result == false) {
        return (false);
    }
    //clear previous errors first then proceed!
    $('.errorsText').html("");
    result = validateGuestAllocations();
    if (result == false) {
        return (false);
    }

    return (true);
}
function validateEmailEnquiryFormWithDateRangePicker(form) {
    var result = validateArriveDepartDateRange();
    if (result == false) {
        return (false);
    }
    //clear previous errors first then proceed!
    $('.errorsText').html("");
    result = validateGuestAllocations();
    if (result == false) {
        return (false);
    }

    return (true);
}

function validateRoomAllocations(isUnitPricing) {
    var roomSelects = $("select[name^=numberOfRoomsRequiredForType]");
    var roomsRequested = 0;

    for (var i = 0; i < roomSelects.length; i++) {
        var roomTypeId = roomSelects[i].name.substring(roomSelects[i].name
                .indexOf("[") + 1, roomSelects[i].name.length - 1);
        var maxPax = $("#maxGuests-" + roomTypeId)[0].innerHTML;
        var numberOfRooms = roomSelects[i].options[roomSelects[i].selectedIndex].value;
        roomsRequested += +numberOfRooms;
        if (numberOfRooms > 0) {
            for (var roomNumber = 0; roomNumber < numberOfRooms; roomNumber++) {
                var span_element_id = "#rooms_required_error_"+roomTypeId+"_"+roomNumber;
                var room_type_error = $(span_element_id);
                var paxAllocs = $("select[name^=guestAllocationsForRoomType\\["
                    + roomTypeId + "\\]\\.guestAllocation\\[" + roomNumber
                    + "\\]]");
                var totalForRoom = 0;
                for (var j = 0; j < paxAllocs.length; j++) {
                    var alloc = paxAllocs[j].options[paxAllocs[j].selectedIndex].value;
                    totalForRoom += Number(alloc);
                }
                if (totalForRoom > maxPax) {
                    $('html, body').animate({
                        scrollTop: $(paxAllocs[0]).offset().top
                    }, 500);
                    if(isUnitPricing){
                        var msg = "Max "+maxPax+" guests allowed";
                    }else{
                        var msg = "Max "+maxPax+" guests allowed";
                    }
                    room_type_error.html(msg);
                    return (false);
                }
            }
        }
    }
    if (roomsRequested == 0) {
        var rooms_required_error = $("#rooms_required_error");
        var rooms_required_error_scrollto = $("#rooms_required_error_scrollto");
        rooms_required_error_scrollto
        $('html, body, .section--form').animate({
            scrollTop: rooms_required_error_scrollto.offset().top
        }, 500);
        $('#mobile_rooms_required_error').addClass('p-b-35');
        rooms_required_error.html("You have not selected any rooms. Please select at least one room and try again");
        return (false);
    }

    return (true);
}
function validateGuestAllocations() {
    var roomSelects = $("select[name^=numberOfRoomsRequiredForType]");
    var roomsRequested = 0;

    for (var i = 0; i < roomSelects.length; i++) {
        var roomTypeId = roomSelects[i].name.substring(roomSelects[i].name
                .indexOf("[") + 1, roomSelects[i].name.length - 1);
        var maxPax = $("#maxGuests-" + roomTypeId)[0].innerHTML;
        var numberOfRooms = roomSelects[i].options[roomSelects[i].selectedIndex].value;
        roomsRequested += +numberOfRooms;

        if (numberOfRooms > 0) {
            for (var roomNumber = 0; roomNumber < numberOfRooms; roomNumber++) {
                var span_element_id = "#rooms_required_error_"+roomTypeId+"_"+roomNumber;
                var room_type_error = $(span_element_id);
                var paxAllocs = $("select[name^=guestAllocationsForRoomType\\["
                    + roomTypeId + "\\]\\.guestAllocation\\[" + roomNumber
                    + "\\]]");
                var totalForRoom = 0;
                for (var j = 0; j < paxAllocs.length; j++) {
                    var alloc = paxAllocs[j].options[paxAllocs[j].selectedIndex].value;
                    totalForRoom += Number(alloc);
                }
                if (totalForRoom == 0) {
                    if(room_type_error){
                        $('html, body').animate({
                            scrollTop: $("#mobile-font-size_"+i).offset().top
                        }, 500);
                    }
                    room_type_error.html("Please allocate guests ");
                    return (false);
                }
                if (totalForRoom > maxPax) {
                    $('html, body').animate({
                        scrollTop: $(paxAllocs[0]).offset().top
                    }, 500);
                    room_type_error.html("Max "+maxPax+" guests allowed");
                    return (false);
                }
            }
        }
    }

    if (roomsRequested == 0) {
        var rooms_required_error = $("#rooms_required_error");
        var rooms_required_error_scrollto = $("#rooms_required_error_scrollto");
        $('html, body, .section--form').animate({
            scrollTop: rooms_required_error_scrollto.offset().top
        }, 500);
        $('#mobile_rooms_required_error').addClass('p-b-35');
        rooms_required_error.html("You have not selected any rooms.<br class='hidden-sm hidden-lg hidden-md'>Please select at least one room and try again");
        return (false);
    }

    return (true);
}

function validateEmailAddress(emailAddress) {
    if (typeof emailAddress === "undefined") {
        return false;
    }
    if (emailAddress.value.length == 0) {
        return (false);
    }
    var atIndex = emailAddress.value.indexOf('@');
    if (atIndex == -1) {
        return (false);
    }
    dotIndex = emailAddress.value.indexOf('.', atIndex);
    if (dotIndex == -1) {
        return (false);
    }
    return true;
}

var dateParsePatterns = [
// yyyy-mm-dd (ISO style)
    {
        re : /(\d{4})-(\d{1,2})-(\d{1,2})/,
        handler : function(bits) {
            var d = new Date();
            d.setDate(1);
            d.setYear(parseInt(bits[1], 10));
            d.setMonth(parseInt(bits[2], 10) - 1);
            d.setDate(parseInt(bits[3], 10));
            return d;
        }
    } ];

function parseDateString(s) {
    for ( var i = 0; i < dateParsePatterns.length; i++) {
        var re = dateParsePatterns[i].re;
        var handler = dateParsePatterns[i].handler;
        var bits = re.exec(s);
        if (bits) {
            return handler(bits);
        }
    }
    throw new Error("Invalid date string");
}

function getStartDateForCalendar() {
    var currentTime = new Date();
    if (currentTime.getHours() > 16
        || (currentTime.getHours() == 16 && currentTime.getMinutes() > 30)) {
        return 1;
    } else {
        return 0;
    }
}

function validateIndexArriveDepartDateFormJQuery() {
    arrive = $("#arrival");
    depart = $("#departure");
    if (arrive && depart) {
        if (arrive.datepicker("getDate") || depart.datepicker("getDate")) {
            return validateArriveDepartDateFormjQuery();
        }
    }
    return true;
}

var arrayOfDatesFromServer = [];

function isDateCorrectFormat(date){
    var dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (!$.trim(date.toString()).match(dateRegex)) {
        alert("The date " + date + " is not in the correct format of 'YYYY-MM-DD', \nplease input again for example 2014-01-01 ");
//		alert("Please input the date in the following format 'YYYY-MM-DD' eg '2014-01-01' ");
    }
}
function validateArriveDepartDateRange() {

   if( $('#date-range-arrival').length ){
        arrive = $("#date-range-arrival").val();
        depart = $("#date-range-departure").val();
    }else{
       arriveError.html("Please select check-in date");
       arriveError.css("display", "block");
       $('html, body').animate({
           scrollTop: arriveError.offset().top
       }, 500);
       arriveError.focus();
       return false;
   }
        arriveError = $("#arrive_error");
        departError = $("#depart_error");
        arriveError.html("");
        arriveError.css("display", "none");
        departError.css("display", "none");
        departError.html("");

        arriveDate = new Date(arrive);
        departDate = new Date(depart);

        if (!arrive) {
            arriveError.html("Please select check-in date");
            arriveError.css("display", "block");
            $('html, body').animate({
                scrollTop: arriveError.offset().top
            }, 500);
            return false;
        }

        if (!depart) {
            departError.html("Please select a check-out date");
            departError.css("display", "block");
            $('html, body').animate({
                scrollTop: departError.offset().top
            }, 500);
            return false;
        }

        if (arriveDate && departDate) {
            if (isBeforeToday(arriveDate)) {
                arriveError.html("Please select a later date");
                arriveError.css("display", "block");
                $('html, body').animate({
                    scrollTop: arriveError.offset().top
                }, 500);
                return false;
            }

            if (arriveDate.getTime() >= departDate.getTime()
                || (isSameDay(arriveDate, departDate))) {
                departError.html("Please select a date after the arrival date");
                departError.css("display", "block");
                $('html, body').animate({
                    scrollTop: departError.offset().top
                }, 500);
                return false;
            }
        } else {
            return true;
        }

}
function validateArriveDepartDateFormjQuery() {

    if( $('#arrival').length )
    {
        arrive = $("#arrival");
        depart = $("#departure");
    }

    if( $('#date-range-arrival').length ){
        arrive = $("#date-range-arrival");
        depart = $("#date-range-departure");
    }

    arriveError = $("#arrive_error");
    departError = $("#depart_error");
    if (arrive && depart && arriveError && departError) {

//		arriveDate = arrive.val();
//		departDate = depart.val();

        arriveDate = arrive.datepicker("getDate");
        departDate = depart.datepicker("getDate");

        if (!arriveDate) {
            arriveError.html("Please select check-in date");
            arriveError.css("display", "block");
            $('html, body').animate({
                scrollTop: arriveError.offset().top
            }, 500);
            arrive.focus();
            return false;
        }

        if (!departDate) {
            departError.html("Please select a check-out date");
            departError.css("display", "block");
            $('html, body').animate({
                scrollTop: departError.offset().top
            }, 500);
            depart.focus();
            return false;
        }

        if (arriveDate && departDate) {
            if (isBeforeToday(arriveDate)) {
                arriveError.html("Please select a later date");
                arriveError.css("display", "block");
                $('html, body').animate({
                    scrollTop: arriveError.offset().top
                }, 500);
//                arrive.focus();
                return false;
            }

            if (arriveDate.getTime() >= departDate.getTime()
                || (isSameDay(arriveDate, departDate))) {
                departError.html("Please select a date after the arrival date");
                departError.css("display", "block");
                $('html, body').animate({
                    scrollTop: departError.offset().top
                }, 500);
//                arrive.focus();
                return false;
            }
        } else {
            return true;
        }
    }
    return true;
}

function getToday() {
    var today = resetTimeComponentOfDate(new Date());
    return today;
}

function resetTimeComponentOfDate(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

function isBeforeToday(checkDate) {
    if(checkDate){
        if (checkDate.getTime() < getToday().getTime())
            return true;
    }
}

function isToday(checkDate) {
    today = new Date();

    if(checkDate){
        if (checkDate.getFullYear() == today.getFullYear()) {
            if (checkDate.getMonth() == today.getMonth()) {
                if (checkDate.getDate() == today.getDate()) {
                    return true;
                }
            }
        }
    }
    return false;
}

function isSameDay(dateOne, dateTwo) {
    if (dateOne.getFullYear() == dateTwo.getFullYear()) {
        if (dateOne.getMonth() == dateTwo.getMonth()) {
            if (dateOne.getDate() == dateTwo.getDate()) {
                return true;
            }
        }
    }
    return false;
}

function removeSpaces(e) {
    if (e.keyCode) {
        code = e.keyCode;
    } else {
        if (e.which) {
            code = e.which;
        }
    }
    if (code == 32) {
        return false;
    }
    return true;
}

function getDateDisplayPropertiesForArrival(arrivalDate) {

    $('#departure').datepicker('enable');
    var minDateForCalender = $('#arrival').datepicker('option', 'minDate');

    var arriveError = $("#arrive_error");

    if($('#arrival').val()){
        arriveError.hide();
    }

    var departError = $("#depart_error");
    departError.hide();

    if (typeof arrivalDate !== "undefined") {



        var yearValue = arrivalDate.getFullYear();

        var dayValue = String(arrivalDate.getDate());
        if (dayValue.length === 1) {
            dayValue = "0" + dayValue;
        }

        var monthValue = String(arrivalDate.getMonth() + 1);
        if (monthValue.length === 1) {
            monthValue = "0" + monthValue;
        }
        var selectedDateString = yearValue + '-' + (monthValue) + '-'
            + dayValue;

        if ((typeof minDateForCalender !== "undefined") && (arrivalDate < minDateForCalender)) {
            return [ false, 'unselectable_white' ];
        }

        if ($.inArray(selectedDateString, arrayOfDatesFromServer) != -1) {
            return [ false, 'unselectable_red' ];
        }

        return [ true, 'selectable_beige' ];
    }
}

function getDateDisplayPropertiesForDeparture(departDate) {
    var maxDateForDeparture = $('#departure').datepicker('option', 'maxDate');
    var minDateForDeparture = $('#departure').datepicker('option', 'minDate');
    var minDateForCalender = $('#arrival').datepicker('option', 'minDate');

    var arriveError = $("#arrive_error");
    if($('#arrival').val()){
        arriveError.hide();
    }

    var departError = $("#depart_error");
    if($('#departure').val()){
        departError.hide();
    }


    if (departDate) {
        var arrayOfUnselectableDates = [];
        for ( var i = 0; i < arrayOfDatesFromServer.length; i++) {
            arrayOfUnselectableDates.push(arrayOfDatesFromServer[i]);
        }

        if ((minDateForCalender) && (departDate < minDateForCalender)) {
            return [ false, 'unselectable_white' ];
        }

        if ((minDateForDeparture) && (departDate < minDateForDeparture)) {
            return [ false, 'unselectable_white' ];
        }

        if (maxDateForDeparture && (departDate > maxDateForDeparture)) {
            return [ false, 'unselectable_white' ];
        }

        var previousDay = parseDateString(decrementDate(convertDateToStringFormat(departDate)));

        var yearValue = previousDay.getFullYear();

        var dayValue = String(previousDay.getDate());
        if (dayValue.length === 1) {
            dayValue = "0" + dayValue;
        }

        var monthValue = String(previousDay.getMonth() + 1);
        if (monthValue.length === 1) {
            monthValue = "0" + monthValue;
        }
        var previousDayString = yearValue + '-' + (monthValue) + '-' + dayValue;

        if ($.inArray(previousDayString, arrayOfUnselectableDates) != -1) {
            return [ false, 'unselectable_red' ];
        }
        return [ true, 'selectable_beige' ];
    }
}

function incrementDate(date_str) {
    var parts = date_str.split("-");
    var dt = new Date(parseInt(parts[0], 10), // year
        parseInt(parts[1], 10) - 1, // month (starts with 0)
        parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
        parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
        parts[2] = "0" + parts[2];
    }
    return parts.join("-");
}

function decrementDate(date_str) {
    var parts = date_str.split("-");
    var dt = new Date(parseInt(parts[0], 10), // year
        parseInt(parts[1], 10) - 1, // month (starts with 0)
        parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() - 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
        parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
        parts[2] = "0" + parts[2];
    }
    return parts.join("-");
}

function setMinAndMaxDateForDeparture(arrivalDateString) {
    $('#departure').val("");
    var newSortedArray = [];

    for ( var i = 0; i < arrayOfDatesFromServer.length; i++) {
        newSortedArray.push(arrayOfDatesFromServer[i]);
    }

    newSortedArray.push(arrivalDateString); // add to array to get index of next unselectable date
    newSortedArray = newSortedArray.sort();

    var indexOfArrivalDateInArray = $.inArray(arrivalDateString, newSortedArray);
    var arrivalDatePlusOne = incrementDate(arrivalDateString);
    arrivalDatePlusOne = resetTimeComponentOfDate(parseDateString(arrivalDatePlusOne));

    $('#departure').datepicker('option', 'minDate', arrivalDatePlusOne);
    if (newSortedArray[indexOfArrivalDateInArray + 1]) { // ****** set maxDate to next unselectable date
        var maxDateString = newSortedArray[indexOfArrivalDateInArray + 1];
        var maxDate = parseDateString(incrementDate(maxDateString));
        $('#departure').datepicker('option', 'maxDate', resetTimeComponentOfDate(maxDate));
    } else {
        $("#departure").datepicker('option', 'maxDate', "");
    }
}

function convertDateToStringFormat(date) {
    var dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (date) {
        if (!$.trim(date.toString()).match(dateRegex)) {
            date = $.datepicker.formatDate("yy-mm-dd", date);
        }
    }
    return date.toString();
}

function getNumDays(firstDate, secondDate) { // assumes checkDates() have been called before!!!
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.ceil(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
}

function setNumberOfDays(arrivalDate, departDate) {

    var newArrivalDate = convertFormattedDateToWesternDate(arrivalDate);
    var newDepartDate = convertFormattedDateToWesternDate(departDate);
    var numDays = getNumDays(newArrivalDate, newDepartDate); // basiccalender js function
    var str = "(" + numDays + " night";
    if (numDays > 1) {
        str += "s";
    }
    str += ")";
    num_nightsEl = $("#num_of_nights_cell");
    num_nightsEl.html(str);
}

function convertFormattedDateToWesternDate(date) {
    date = parseDateString(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

function instatiateResultsPageCalendars(arrival, departure) {
    var shortMonthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September",
        "October", "November", "December" ];
    var minDateForCalendar = getToday();
    $('#' + arrival).datepicker({
        buttonText : 'Choose check-in date',
        buttonImage : '/img/newlook/calender-icon.png',
        numberOfMonths : 1,
        buttonImageOnly : true,
        minDate : minDateForCalendar,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        monthNamesShort : shortMonthNames,
        onSelect : function(dateText) {
            var fromDate = parseDateString(dateText);
            var minToDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 1);
            $('#' + departure).datepicker('option', 'minDate', minToDate);
        },
        onClose:function()
        {
            $('#departure').datepicker('show');
        }
    }).attr('readonly','true');

    $('#' + departure).datepicker({
        buttonText : 'Choose check-out date',
        buttonImage : '/img/newlook/calender-icon.png',
        numberOfMonths : 1,
        buttonImageOnly : true,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        monthNamesShort : shortMonthNames
    }).attr('readonly','true');
}

function instatiateHomePageCalendars(arrival, departure) {
    var shortMonthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September",
        "October", "November", "December" ];
    var minDateForCalendar = getToday();
    $('#' + arrival).datepicker({
        buttonText : 'Choose check-in date',
        buttonImage : '/img/newlook/calender-icon.png',
        numberOfMonths : 1,
        buttonImageOnly : true,
        minDate : minDateForCalendar,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        monthNamesShort : shortMonthNames,
        onSelect : function(dateText) {
            var fromDate = parseDateString(dateText);
            var minToDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 1);
            $('#' + departure).datepicker('option', 'minDate', minToDate);
        }
    }).attr('readonly','true');

    $('#' + departure).datepicker({
        buttonText : 'Choose check-out date',
        buttonImage : '/img/newlook/calender-icon.png',
        numberOfMonths : 1,
        buttonImageOnly : true,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        monthNamesShort : shortMonthNames
    }).attr('readonly','true');
}

function instatiateCalendarsWithBlockOuts(inArrival, inDeparture) {
    var shortMonthNames = [ "January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October", "November",
        "December" ];

    var num_nightsEl = null;
    var minDateForCalendar = getToday();
    var minDateForDepature =  getToday();
    minDateForDepature.setDate(minDateForDepature.getDate() + 1);

    $('#arrival').datepicker({
        buttonText : 'Choose check-in date',
        buttonImage : '/img/newlook/calender-icon.png',
        buttonImageOnly : true,
        minDate : minDateForCalendar,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        monthNamesShort : shortMonthNames,
        beforeShowDay : getDateDisplayPropertiesForArrival,
        onSelect : function(arrivalDateString) {

            var arrive = $('#arrival').val();
            var depart= $('#departure').val();
            if (depart && arrive < depart) {
                if (num_nightsEl) {
                    num_nightsEl.html("");
                }
            } else if (arrive >= depart) {
                num_nightsEl = $("#num_of_nights_cell");
                num_nightsEl.html("");
                $('#departure').val("");
            }
            if (num_nightsEl) {
                $('#num_nightsEl').val("");
            }
            if (num_nightsEl) {
                num_nightsEl.html("");
            }
            $('#departure').val("");
            setMinAndMaxDateForDeparture(arrivalDateString);

        },
        onClose:function()
        {
            $('#departure').datepicker('show');
        }
    }).attr('readonly','true');

    $('#departure').datepicker({
        buttonText : 'Choose check-out date',
        buttonImage : '/img/newlook/calender-icon.png',
        buttonImageOnly : true,
        showOn : 'both',
        dateFormat : 'yy-mm-dd',
        changeYear : true,
        yearRange : 'c-00:c+02',
        changeMonth : true,
        showButtonPanel : true,
        minDate : minDateForDepature,
        monthNamesShort : shortMonthNames,
        beforeShowDay : getDateDisplayPropertiesForDeparture,
        onSelect : function(departDate) {
            if (num_nightsEl) {
                num_nightsEl.html("");
            }
            if (num_nightsEl) {
                $('#num_nightsEl').val("");
            }
            var arrive = $('#arrival').val();

            if(arrive === null || arrive === ''){
                alert("Please enter the arrival date first");
            }
            var depart= $('#departure').val();
            if ((arrive !== null && arrive !== '') && (depart !== null && depart !== '')) {
                setNumberOfDays(arrive, depart);
                $('#arrive_error').hide();
                $('#depart_error').hide();
            }
        }
    }).attr('readonly','true');
    if(inArrival === '' && inDeparture === ''){
        $("#departure").datepicker('disable');
    }
}

function validateCreditCard(value) {
    if (value === undefined) {
        return false;
    }
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;

    value = value.replace(/\D/g, "");
    if (value === '' || value.length < 12 || value.length > 19) {
        return false;
    }

    for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) == 0;
}

function getCookieByName(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2)
        return parts.pop().split(";").shift();
}


